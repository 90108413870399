* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

@font-face {
  font-family: 'Dumbnerd';
  src: url(/static/media/dumbnerd.402a6ee5.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-Bold';
  src: url(/static/media/LEMONMILK-Bold.658b72ac.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-BoldItalic';
  src: url(/static/media/LEMONMILK-BoldItalic.9a354b18.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-Light';
  src: url(/static/media/LEMONMILK-Light.a0a2368c.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-LightItalic';
  src: url(/static/media/LEMONMILK-LightItalic.2938a5cb.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-Medium';
  src: url(/static/media/LEMONMILK-Medium.566ce866.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-MediumItalic';
  src: url(/static/media/LEMONMILK-MediumItalic.3a83c7b7.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-Regular';
  src: url(/static/media/LEMONMILK-Regular.ee1b3583.ttf) format('truetype');
}

@font-face {
  font-family: 'LemonMilk-RegularItalic';
  src: url(/static/media/LEMONMILK-RegularItalic.53f7010a.ttf) format('truetype');
}

.font-dumbnerd {font-family: Dumbnerd;}
.font-lemonmilk-bold {font-family: LemonMilk-Bold;}
.font-lemonmilk-bold-italic {font-family: LemonMilk-BoldItalic;}
.font-lemonmilk-light {font-family: LemonMilk-Light;}
.font-lemonmilk-light-italic {font-family: LemonMilk-LightItalic;}
.font-lemonmilk-medium {font-family: LemonMilk-Medium;}
.font-lemonmilk-medium-italic {font-family: LemonMilk-MediumItalic;}
.font-lemonmilk-regular {font-family: LemonMilk-Regular;}
.font-lemonmilk-regular-italic {font-family: LemonMilk-RegularItalic;}

body {
  background-image: url(/static/media/background.b4205a7a.jpg);
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-color: black;
}

@media screen and (max-width: 2560px) {
  body {
    background-size: 1920px;
    background-position: center top;
  }
}

@media screen and (max-width: 1920px) {
  body {
    background-size: 1920px;
    background-position: center top;
  }
}

@media screen and (max-width: 768px) {
  body {
    background-size: 768px;
    background-position: left top;
  }
}

.swiper-slide {
  background-position: center;
  background-size: auto 100%;
  width: 300px;
  height: 550px;
}
  
.swiper {
  width: 900px;
}

@media only screen and (max-width: 1224px) {
  .swiper {
    width: 320px;
    padding: 0 20px;
  }

  .swiper-button-prev {
    padding-right: 50px;
  }
  
  .swiper-button-next { 
    padding-left: 50px;
    
  }

}

.swiper-slide img {
  display: flex;
  width: 100%;
}

